@use 'mixins' as *;

.keyvisual {
  max-width: var(--container-width);
  margin: 0 auto;
  position: relative;

  video {
    mask-image: radial-gradient(white, black);
    backface-visibility: hidden;
  }
}

.lead {
  .home & {
    .content {
      @include bp(large) {
        padding: 0 6rem;
      }
    }
  }

  &--grey {
    background: var(--color-grey-light);

    h1 {
      margin: 0;
    }
  }
}

.home {
  .keyvisual {
    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 1;
    //   width: 100%;
    //   height: 100%;
    //   background: url('../static/keyvisual-overlay.png') no-repeat;
    //   background-size: 100%;
    // }

    &__scroll {
      display: none;

      // @include bp(large) {
      //   position: absolute;
      //   right: 10%;
      //   top: 25%;
      //   display: flex;
      //   align-items: center;
      //   flex-direction: column;
      //   z-index: 2;

      //   .icon {
      //     transform: rotate(180deg);
      //     color: var(--color-green);
      //     margin-top: 1.5rem;
      //     font-size: 2rem;
      //     animation: bounce 3s infinite;
      //   }
      // }

      &.-slo {
        top: 34%;
      }
    }
  }

  .cards-container {
    display: block;

    article {
      margin-bottom: 2rem;
    }

    @include bp(medium) {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      position: relative;

      article {
        margin-bottom: 0;
      }

      .-with-margin {
        margin-top: 10rem;
      }

      &__link {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    @include bp(large) {
      gap: 4rem;
    }
  }

  .clients-container {
    padding: 0 100px;

    @include bp(small) {
      padding: 0;
    }
  }
}

.contact {
  &__grid {
    @include bp(medium) {
      display: grid;
      grid-template-columns: 2fr 3fr;
    }
  }

  &__map {
    margin-top: 2rem;

    @include bp(medium) {
      margin-top: 0;
    }

    #map {
      height: rem(360px);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__types {
    ul {
      padding: 0;
    }

    li {
      padding-left: 0;

      &::before {
        content: none;
      }

      a {
        font-weight: 400;
        text-decoration: none;
      }

      .icon {
        margin-right: 1rem;
      }
    }
  }

  &__social {
    .icon {
      font-size: 1.5rem;
      color: var(--color-black);
      margin-right: 1rem;
    }

    a {
      &:hover {
        .icon {
          color: var(--color-green);
        }
      }
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: rotate(180deg) translateY(0);
  }

  40% {
    transform: rotate(180deg) translateY(-10px);
  }

  60% {
    transform: rotate(180deg) translateY(-5px);
  }
}

.left-right-section {
  display: flex;
  flex-direction: column-reverse;

  @include bp(medium) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4rem;

    &.-inverted {
      flex-direction: row-reverse;
    }

    & > div {
      width: 50%;
    }
  }

  &.-centered {
    @include bp(large) {
      align-items: center;
    }
  }
}

.small-only-margin {
  margin-top: 1rem;

  @include bp(large) {
    margin-top: 0;
  }
}

.last-block {
  padding-bottom: rem(80px);

  @include bp(medium) {
    padding-bottom: rem(160px);
  }
}

#wcb {
  text-align: left !important;
  margin-top: 1rem !important;
  font-size: 12px !important;

  #wcb_a,
  #wcb_g {
    border: 0.13em solid var(--color-green) !important;
  }

  #wcb_a {
    background: var(--color-green) !important;
  }

  #wcb_g {
    color: var(--color-black) !important;
  }

  #wcb_2 {
    color: var(--color-green) !important;
  }
}
