/**
 *
 * Typography
 *
 */

@use 'vars';
@use 'mixins' as *;

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

strong,
.bold {
  font-weight: 700;
}

h1,
.h1 {
  font-size: var(--h1);
  margin-bottom: 1em;
  line-height: 1.5;
  @extend .semi-bold;
}

h2,
.h2 {
  font-size: var(--h2);
  margin-bottom: 1em;
  line-height: 1.5;
  @extend .bold;

  a {
    text-decoration: none;
    color: var(--color-black);
  }
}

h3,
.h3 {
  font-size: var(--h3);
  margin-bottom: 1em;
  line-height: 1.5;
  @extend .semi-bold;
}

h4,
.h4 {
  font-size: var(--h4);
  margin-bottom: 1em;
  line-height: 1.5;
  @extend .semi-bold;
}

p {
  margin-bottom: 1.5em;
  @extend .regular;
}

.small,
small {
  font-size: var(--small);
  @extend .regular;
}

.caps {
  font-size: var(--caps);
  line-height: 1.7;
  text-transform: uppercase;
  @extend .regular;
}

ul {
  padding-left: 0;

  li {
    list-style: none;
    color: var(--color-grey);
    padding-left: 1rem;
    position: relative;
    margin-bottom: 0.5rem;

    &::before {
      position: absolute;
      top: 0.5rem;
      left: 0;
      content: '';
      background: url('../static/dagency-arrow.svg') no-repeat;
      width: 6.27px;
      height: 10px;
      background-size: 100%;
    }
  }
}
