@use 'mixins' as m;

:root {
  // colors
  --color-white: #fff;
  --color-black: #000;
  --color-green: #81d33f;
  --color-green-dark: #56ab11;
  --color-grey: #737373;
  --color-grey-light: #f6f6f6;
  --color-error: #ee2f3f;
  --color-warning: #ffcb5c;
  --color-success: #81d33f;
  --green-gradient: linear-gradient(90deg, #81d33f 0%, #569c0b 100%);

  // fonts
  --small: clamp(0.89rem, 0.91rem + -0.09vw, 0.83rem);
  --body: clamp(1rem, 1rem + 0vw, 1rem);
  --caps: clamp(1.12rem, 1.09rem + 0.12vw, 1.2rem);
  --h4: clamp(1.25rem, 1.19rem + 0.28vw, 1.44rem);
  --h3: clamp(1.41rem, 1.3rem + 0.48vw, 1.73rem);
  --h2: clamp(1.57rem, 1.41rem + 0.74vw, 2.07rem);
  --h1: clamp(1.76rem, 1.52rem + 1.08vw, 2.49rem);

  // shadows
  --box-shadow: 0.25rem 0.25rem 0.75rem 0 rgb(0 0 0 / 0.15);
  --box-shadow-down: 0.25rem 0 0.75rem 0 rgb(0 0 0 / 0.15);

  // general
  --container-width: 1440px;
  --inner-container-width: 1120px;
  --font-body: 'Montserrat', sans-serif;

  // transitions
  --easing: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --transition-duration: 0.2s;

  // spacings
  --spacing-tiny: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --spacing-xl: 2.5rem;
  --spacing-xxl: 3rem;

  @include m.bp(medium) {
    --spacing-tiny: 0.5rem;
    --spacing-sm: 2rem;
    --spacing-md: 3rem;
    --spacing-lg: 4rem;
    --spacing-xl: 6rem;
    --spacing-xxl: 10rem;
  }

  --border-radius: 0.25rem;
}
