@use 'mixins' as *;

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  overflow: hidden;
  fill: currentColor;
  stroke: currentColor;
  pointer-events: none;
}

.hide-mobile {
  display: none;

  @include bp(medium) {
    display: inline-block;
  }
}

.hide-desktop {
  display: inline-block;

  @include bp(medium) {
    display: none;
  }
}

.hidden {
  display: none !important;
}

.green {
  color: var(--color-green);
}

.grey {
  color: var(--color-grey);
}

.grey-bg {
  background-color: var(--color-grey-light);

  &.-with-before {
    background-color: transparent;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      min-height: rem(1100px);
      top: 0;
      left: 0;
      background-color: var(--color-grey-light);
      z-index: -1;
    }

    @include bp(small) {
      padding-bottom: 2rem;
    }

    @include bp(medium) {
      padding-bottom: 8rem;
    }

    @include bp(large) {
      padding-bottom: 0;
    }
  }
}
