@use '../../assets/styles/mixins' as *;

$className: 'footer';

.#{$className} {
  color: var(--color-white);
  background: var(--color-black);

  .container {
    padding: 4rem 1.5rem calc(6rem - 21px - 28px);
  }

  &__content {
    position: relative;
    max-width: var(--inner-container-width);
    margin: 0 auto;
    display: grid;

    @include bp(medium) {
      grid-template-columns: minmax(auto, 750px) 1fr;
      grid-template-rows: 100%;
    }
  }

  &__logo {
    .da-logo {
      display: block;
      height: rem(25px);
      margin-bottom: 2rem;

      @include bp(medium) {
        height: rem(50px);
        margin-top: 0.2rem;
      }
    }

    .tos-logo {
      max-width: rem(250px);
      margin-bottom: 1.5rem;
    }

    @include bp(medium) {
      grid-column: 3;
      grid-row: 1;

      .da-logo {
        margin-left: auto;
        margin-bottom: 3.5rem;
      }

      .tos-logo {
        max-width: rem(300px);
      }
    }
  }

  &__text {
    max-width: rem(600px);

    h3 {
      margin-bottom: 2rem;
    }

    p.bold {
      margin-bottom: 0.5rem;
    }

    p.light {
      margin-bottom: 0.5rem;

      &.first {
        margin-bottom: 1.5rem;
      }

      a {
        text-decoration: none;
        color: var(--color-white);
        padding: 0;
        font-weight: 300;

        @media (hover: hover) {
          &:hover {
            color: var(--color-green);
          }
        }
      }
    }

    @include bp(medium) {
      grid-column: 1;
      grid-row: 1;
    }
  }

  &__social {
    width: 100%;

    a {
      margin-top: 3rem;
      display: inline-block;
      color: var(--color-green);
      margin-right: 2rem;
      text-decoration: none;

      .icon {
        font-size: rem(35px);
        transition: color var(--transition-duration) var(--easing);
      }

      &:last-child {
        margin-right: 0;
      }

      @media (hover: hover) {
        &:hover {
          .icon {
            color: var(--color-white);
          }
        }
      }
    }

    @include bp(medium) {
      grid-column: 2;
      grid-row: 1;
      position: absolute;
      bottom: rem(21px);
      text-align: right;

      a {
        margin-top: 0;
      }
    }
  }
}
