@use 'mixins' as *;

a {
  color: var(--color-grey);
  transition: all var(--transition-duration) var(--easing);
  text-decoration: underline;
  font-weight: 700;

  @media (hover: hover) {
    &:hover {
      color: var(--color-green);
    }
  }

  &.arrow {
    text-decoration: none;
    padding: 0.5rem;
    display: inline-block;

    .icon {
      margin-right: 1rem;
      color: var(--color-green);
      font-size: 2rem;
      transform: rotate(90deg);
      transition: transform var(--transition-duration) var(--easing);
    }

    @media (hover: hover) {
      &:hover {
        transform: translateX(4px);
      }
    }
  }
}
