@use '../../assets/styles/mixins' as *;

$className: 'button';

.#{$className} {
  appearance: none;
  cursor: pointer;
  margin: 0 auto 1.5rem;
  text-align: center;
  backface-visibility: hidden;
  border-radius: rem(5px);
  border: rem(1px) solid var(--color-green);
  padding: rem(6px) rem(12px);
  background-color: var(--color-green);
  color: var(--color-white);
  text-decoration: none;
  transition: all var(--transition-duration) var(--easing);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: rem(12px);
  line-height: 1.5rem;
  font-weight: 600;
  white-space: nowrap;

  .icon {
    color: var(--color-white);
    font-weight: 700;
    width: 1rem;
    transition: all 0.25s ease;
    margin-left: 1rem;
    transform: rotate(90deg);
  }

  @media (hover: hover) {
    &:hover {
      color: var(--color-green);
      background-color: var(--color-white);
      border-color: var(--color-green);

      .icon {
        color: var(--color-green);
      }
    }
  }

  @include bp(medium) {
    font-size: rem(20px);
    font-weight: 700;
    padding: rem(12px) 1.5rem;
  }
}
