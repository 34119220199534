@use '../../assets/styles/mixins' as *;

.lang-switch {
  width: 88px;
  height: 36px;
  position: absolute;
  background-color: var(--color-grey-light);
  padding: 0.5rem 1rem;
  z-index: 3;
  left: var(--lang-switch-position);
  top: 0;
  display: none;
  opacity: 0;

  @include bp(large) {
    display: block;
  }

  &.-inside-drawer {
    bottom: 140px;
    right: 1.5rem;
    left: unset;
    top: unset;
    display: block;
  }

  ul {
    display: flex;
    gap: 0.5rem;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 0;
      margin: 0;

      &::before {
        display: none;
      }

      a {
        display: block;
        color: var(--color-black);
        font-weight: 500;
        font-size: rem(14px);
        line-height: 1rem;
        text-decoration: none;

        @media (hover: hover) {
          &:hover {
            color: var(--color-green);
          }
        }
      }

      &:first-child {
        a {
          border-right: 1px solid var(--color-black);
          padding-right: 0.5rem;
        }
      }

      &.current {
        a {
          color: var(--color-green);
        }
      }
    }
  }
}
