@use '../../assets/styles/mixins' as *;

table {
  min-width: 100%;
  margin-bottom: 2rem;

  caption {
    text-align: left;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.105em;
    margin-bottom: rem(10px);
    padding-left: 1rem;
  }

  thead {
    background-color: #d6d6d6;

    tr {
      th {
        color: var(--color-white);
        font-weight: 600;
        text-align: left;
        padding: rem(12px) 1rem;
      }
    }
  }

  tbody {
    tr {
      border-bottom: rem(1px) solid var(--color-grey-light);

      td {
        padding: 1rem;
      }
    }
  }

  &.toc {
    @extend .margin-bottom-lg;
  }
}
