@use '../../assets/styles/mixins' as *;

$className: 'youtube';

.#{$className} {
  position: relative;
  aspect-ratio: 16 / 9;

  iframe {
    width: 100%;
    height: 100%;
  }

  &__play {
    width: 4rem;
    aspect-ratio: 1;
    border-radius: var(--border-radius);
    background-color: var(--color-green);
    display: grid;
    place-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background-color var(--transition-duration) var(--easing);

    .icon {
      font-size: 1rem;
      color: var(--color-white);
      pointer-events: none;
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-green-dark);
      }
    }

    @include bp(medium) {
      width: 5rem;
      aspect-ratio: 1;

      .icon {
        font-size: 1.5rem;
      }
    }

    &.-is-hidden {
      display: none;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all var(--transition-duration) var(--easing);

    &.-fade-out {
      visibility: hidden;
      opacity: 0;
    }

    img {
      object-fit: cover;
    }
  }
}
