@use '../../assets/styles/mixins' as *;

$className: 'card';

.#{$className} {
  a {
    text-decoration: none;
    display: grid;
    grid-template:
      [box-start] 'title arrow' [box-end] /
      [box-start] 1fr auto [box-end];

    & > * {
      grid-area: box;
    }

    .#{$className}__image {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgb(255 255 255 / 0) 14.22%, var(--color-black) 92.04%);
        top: 0;
        left: 0;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgb(255 255 255 / 0) 14.22%, var(--color-black) 100%);
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity var(--transition-duration) var(--easing);
      }
    }

    .#{$className}__title {
      grid-area: title;
      place-self: end start;
      padding: 0 2rem;
      color: var(--color-white);
      z-index: 1;
    }

    .#{$className}__arrow {
      grid-area: arrow;
      place-self: end;
      padding: 2rem;
      transition: transform var(--transition-duration) var(--easing);

      .icon {
        color: var(--color-green);
        font-size: 2rem;
        transform: rotate(90deg);
      }
    }

    @media (hover: hover) {
      &:hover {
        .#{$className}__image {
          &::after {
            opacity: 1;
          }
        }

        .#{$className}__arrow {
          transform: translateX(10px);
        }
      }
    }
  }

  &.-hollow {
    border: 1px solid var(--color-green);
    max-width: rem(546px);
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;

    p {
      color: var(--color-grey);

      &.bold {
        margin-top: auto;
        color: var(--color-black);
      }

      &.small {
        margin-top: 1rem;
      }
    }
  }
}
