@use '../../assets/styles/mixins' as *;

$className: 'header';

.#{$className} {
  position: relative;
  background: var(--color-white);
  padding: rem(20px) 1.5rem;

  @include bp(large) {
    padding: rem(64px) 1.5rem;
  }

  .container {
    max-width: var(--inner-container-width);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    .logo {
      display: block;

      img {
        height: rem(25px);
        margin-top: 0.3125rem;
        transition: height var(--transition-duration) var(--easing);

        @include bp(large) {
          height: rem(50px);
          margin-top: 0;
        }
      }
    }
  }
}
