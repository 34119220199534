@use '../../assets/styles/mixins' as *;

$className: 'swiper';

.#{$className} {
  padding-bottom: 4rem;

  @include bp(medium) {
    padding-bottom: 0.25rem;
  }

  &-pagination-bullets {
    bottom: 0 !important;
  }

  &-pagination-bullet {
    width: rem(25px);
    height: rem(25px);
    margin: 0 0.5rem !important;
    border: 1px solid var(--color-green);
    opacity: 1;
    position: relative;
    background: var(--color-white);

    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 17px;
      height: 17px;
      background-color: var(--color-white);
      border-radius: 50%;
    }

    &-active {
      &::after {
        background-color: var(--color-green);
      }
    }
  }

  &-pagination {
    @include bp(medium) {
      display: none;
    }
  }

  &__arrows {
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 2rem;
    gap: 2rem;
    display: none;

    @include bp(medium) {
      display: flex;
    }
  }

  &-button-next {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 0;
    width: 43px;
    height: 28px;

    .icon {
      color: var(--color-green);
      transform: rotate(90deg);
      font-size: 43px;
    }
  }

  &-button-prev {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 0;
    width: 43px;
    height: 28px;

    .icon {
      color: var(--color-green);
      transform: rotate(-90deg);
      font-size: 43px;
    }
  }

  &-slide {
    height: auto;
  }

  .block {
    picture {
      margin-bottom: 3rem;
    }
  }

  &.swiper--gallery {
    .swiper-slide {
      img {
        aspect-ratio: 16 / 10;
        object-fit: cover;
      }
    }
  }
}
