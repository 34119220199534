@use '../../assets/styles/mixins' as *;

$className: 'nav';
$menuPosition: right !default;
$menuLineThickness: rem(3px) !default;
$menuLineSpacing: rem(5px) !default;

.#{$className} {
  flex-grow: 1;

  a,
  button {
    text-decoration: none;
    border: none;
    background: transparent;
    color: var(--color-black);
    cursor: pointer;
    padding: 0;
  }

  &__l1 {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 0;
    padding: 0;
    list-style: none;

    @include bp(large) {
      gap: 2rem;
    }

    &-i {
      margin: 0;
      padding: 0;
      position: relative;
      white-space: nowrap;
      text-align: center;
      width: 100px;

      &::before {
        display: none;
      }

      &.current {
        a {
          color: var(--color-green);
          font-weight: 500;
        }
      }
    }

    &-a {
      color: var(--color-grey);
      font-size: rem(20px);
      line-height: rem(30px);
      text-transform: lowercase;
      font-weight: 400;

      @media (hover: hover) {
        &:hover {
          font-weight: 500;
        }
      }
    }
  }
}

@mixin menu($float-position) {
  appearance: none;
  border: none;
  background: transparent;
  z-index: 2;
  position: absolute;
  top: 0;
  cursor: pointer;
  display: block;

  @include bp(large) {
    display: none;
  }

  @if $float-position == left {
    left: 0;
  } @else if $float-position == right {
    right: 0;

    @include bp(large) {
      right: 1rem;
    }
  } @else {
    @error "Please use one of the following options `left, right`.";
  }
}

.hamburger {
  @include menu($menuPosition);

  &__icon-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    &::before,
    &::after,
    span {
      content: '';
      background-color: var(--color-black);
      display: block;
      height: $menuLineThickness;
      width: rem(28px);
      margin: $menuLineSpacing 0;
      border-radius: 0.5rem;
      transition: transform var(--transition-duration) var(--easing);
    }

    .hamburger[aria-expanded='true'] & {
      &::before {
        background-color: var(--color-black);
        transform: translateY($menuLineThickness + $menuLineSpacing) rotate(135deg);
      }

      &::after {
        background-color: var(--color-black);
        transform: translateY(-($menuLineThickness + $menuLineSpacing)) rotate(-135deg);
      }

      span {
        background-color: var(--color-black);
        transform: scale(0);
      }
    }
  }
}

@mixin drawer($side) {
  &:not(.disabled) {
    z-index: 2;
    position: fixed;
    top: 4rem;
    bottom: 0;
    width: 100%;
    transition: transform var(--transition-duration) var(--easing);

    .drawer__scroll {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      backface-visibility: hidden;
      background: var(--color-white);
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }

  @if $side == left {
    transform: translateX(-100%);
    left: 0;
  } @else if $side == right {
    right: 0;
    transform: translateX(100%);
  } @else if $side == over {
    left: 0;
    opacity: 0;
    width: 100%;

    &[aria-hidden='false'] {
      opacity: 1;
    }
  } @else {
    @error "Please use one of the following options `left, right, over`.";
  }

  // Visibility
  &[aria-hidden='false'] {
    transform: translateX(0);
  }
}

.drawer {
  @media screen and (max-width: 1023px) {
    @include drawer($menuPosition);

    &[aria-hidden='false'] {
      .#{$className} {
        padding: rem(80px) 1rem rem(40px) rem(40px);

        &__l1 {
          flex-direction: column;
          gap: 3rem;

          &-i {
            width: auto;
            text-align: left;

            a {
              position: relative;
              display: flex;
              padding-left: 2rem;

              &::before {
                content: '';
                background-position: center center;
                background-image: url('../../assets/static/dagency-arrow.svg');
                background-repeat: no-repeat;
                width: 9px;
                height: 14px;
                background-size: 100%;
                position: absolute;
                top: 8px;
                left: 10px;
                transition: left var(--transition-duration) var(--easing);
              }

              @media (hover: hover) {
                &:hover {
                  font-weight: 500;

                  &::before {
                    left: 14px;
                  }
                }
              }
            }

            &.current {
              a {
                color: var(--color-green);
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  .drawer__footer {
    height: rem(140px);
    padding: 2rem 1.5rem;
    background-color: var(--color-black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-white);

    @include bp(large) {
      display: none;
    }

    p {
      margin: 0 0 0.2rem;

      a {
        color: var(--color-white);
        font-weight: 300;
        text-decoration: none;

        @media (hover: hover) {
          &:hover {
            color: var(--color-green);
          }
        }
      }
    }

    &-right {
      a {
        display: inline-block;
        margin: 0 0 0.5rem 1rem;

        .icon {
          color: var(--color-green);
          font-size: rem(35px);
          transition: color var(--transition-duration) var(--easing);
        }

        @media (hover: hover) {
          &:hover {
            .icon {
              color: var(--color-white);
            }
          }
        }
      }
    }
  }
}
