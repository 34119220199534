@use 'vars';
@use 'mixins' as *;

body {
  font-family: var(--font-body);
  display: flex;
  flex-direction: column;
  line-height: 1.7;
  font-size: var(--body);
  overflow-x: hidden;

  @include bp(large) {
    position: relative;
  }
}

main {
  flex-grow: 1;
}

.container {
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
}

.inner-container {
  max-width: var(--inner-container-width);
  margin: 0 auto;
  width: 100%;
}

.overlay {
  background: var(--color-black);
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
}
